import { TeamsFxContext } from "./Context";
import { useContext } from "react";
import { Image, Button } from "@fluentui/react-components";
import { app, authentication} from "@microsoft/teams-js";
import { useNavigate } from "react-router-dom";

export default function AuthenticationStart() {
  const { themeString } = useContext(TeamsFxContext);
  const navigate = useNavigate()

  interface AuthenticatePopUpParameters {
    url: string;
    width?: number;
    height?: number;
    isExternal?: boolean;
  }

  function ThirdPartyLoginButton() {
    function navigateToThirdPartyLogin() {
      const redirectUrl = new URL(`https://${window.location.hostname}:${window.location.port}`)
      if(!redirectUrl.toString().includes('authentication/end')) {
        redirectUrl.pathname = redirectUrl.pathname + 'authentication/end'
      }
      const authenticationUrl = new URL(localStorage.getItem('instanceUrl') ?? "")
      authenticationUrl.pathname =  'ms-teams-embedded/authorization/login'
      authenticationUrl.searchParams.append('redirectUrl', redirectUrl.toString())
      const authenticatePopUpParameters: AuthenticatePopUpParameters = {
        url: authenticationUrl.toString(),
        width: 600,
        height: 535
      }
      console.log(`AuthenticationStart: open popup with ${authenticatePopUpParameters.url}`)
      authentication.authenticate(authenticatePopUpParameters)
      .then(
        (oAuthParams) => {
          console.log(`AuthenticationStart: Resolved with result ${oAuthParams} and navigate to /tab`)
          navigate('/tab', {state: JSON.parse(oAuthParams)})
        })
      .catch( (result) => {console.log(result, 'authentication.authenticate catch')}) ;
    }

    return (
      <Button type="button" onClick={navigateToThirdPartyLogin} style={{ width: '25%', marginLeft: 'auto', marginRight: 'auto'}}>
        Login
      </Button>
    );
  }

  return (
      <div
      className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
    >
      <div className="authentication page">
          <div style={{padding: '10px'}}>
          <Image src="../bee_configuration_page.png" alt="Bee360 Authentication" style={{width:'25%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
          </div>
          
          <h1 style={{fontSize: '1.5em', textAlign: 'center'}}> Add Bee360 to a Team </h1>
          <br/>
          <div style={{ display: 'flex', flexDirection: 'column', width:'85%', margin: 'auto'}}>
            <div style={{padding: '10px'}}>
              Bee360 now integrates with Teams <br/>
              The Bee360 content will be shown after you log in.
            </div>
            <ThirdPartyLoginButton/>
        </div>
      </div>
    </div>
  );
}

