import { TeamsFxContext } from "./Context";
import { useContext, useState } from "react";
import {
  Label,
  Image,
  Input,
} from "@fluentui/react-components";
import { app, pages } from "@microsoft/teams-js";

export default function TabConfiguration() {
    const { themeString } = useContext(TeamsFxContext);
    const [tabName, setTabName] = useState<string>('Bee360')
    const [tabUrl, setTabUrl] = useState<string>('')
    const handleTabNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTabName(event.target.value);
        validateSettings()
    };
    const handleTabUrlInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTabUrl(event.target.value);
        validateSettings()
    };
    initialize(tabName, tabUrl)

    return (
        <div
        className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
      >
        <div className="configuration page">
            <Image src="bee_configuration_page.png" alt="Bee360 Configuration Page" style={{width:'25%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
            <h1 style={{fontSize: '1.5em', textAlign: 'center'}}> Add Bee360 to your Teams context. </h1>
            <br/>
            <div style={{ display: 'flex', flexDirection: 'column', width:'85%', margin: 'auto'}}>
              <Label htmlFor="tabTitleInput" size="small" style={{display:'block'}}>
                Enter a tab name
              </Label>
              <Input
                id = "tabTitleInput"
                type="text"
                autoFocus
                onChange={handleTabNameInput}
                required
                value={tabName}
              />
              <br/>
              <Label htmlFor="tabUrlInput" size="small" required>
                Enter a Bee360 page URL
              </Label>
              <Input
                id = "tabUrlInput"
                type="url"
                required
                onChange={handleTabUrlInput}
                value={tabUrl}
                placeholder="https://demo.bee360.rocks"
              />
          </div>
        </div>
      </div>
    );
  }

function validateSettings() {
  pages.config.setValidityState(true);
}

function initialize(tabName: string, tabUrl: string) {
  app.initialize().then(() => {
    // Initialize the Microsoft Teams SDK
    pages.config.registerOnSaveHandler((saveEvent) => {
      const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
      if (tabUrl) {
        const verifiedTabUrl = new URL(tabUrl)
        addTeamsEmbeddedPathToPath(verifiedTabUrl)
        console.log('TabConfiguration', 'Navigate after config done: ' + baseUrl + "/tab?" + new URLSearchParams({tabUrl: verifiedTabUrl.toString()}).toString())
        pages.config.setConfig({
          suggestedDisplayName: tabName,
          entityId: "bee360ContentTab",
          contentUrl: baseUrl + "/tab?" + new URLSearchParams({tabUrl: verifiedTabUrl.toString()}).toString(),
          websiteUrl: baseUrl + "/tab?" + new URLSearchParams({tabUrl: verifiedTabUrl.toString()}).toString(),
        }).then(() => {
          saveEvent.notifySuccess();
        });
      }
    });
  });
}

function addTeamsEmbeddedPathToPath(tabUrl: URL) {
  tabUrl.pathname = '/ms-teams-embedded' + tabUrl?.pathname
}
