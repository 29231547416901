import { useContext } from "react";
import { TeamsFxContext } from "./Context";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";

export default function Privacy() {
  const { themeString } = useContext(TeamsFxContext);
  const navigate = useNavigate()
  
  function reload() {
    const iframe = (document.getElementById('some_frame_id') as HTMLIFrameElement)
    iframe.src += '';
  }

  function navigateToSelf() {
    const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
    navigate(`/tab?tabUrl=http://localhost:4200`)
        
  }

  function hrefToSelf() {
    const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
    window.location.assign(`${baseUrl}/tab?tabUrl=https://localhost:4200/ms-teams-embedded/authorization/callback/login`)
  }

  function hrefToOtherTab() {
    const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
    window.location.assign(`${baseUrl}/tab`)
  }

  console.log('Privacy')

  return (
    <div
      className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
    >
      <div>
        <Button onClick={reload}>Reload IFrame</Button>
        <Button onClick={navigateToSelf}>Navigate to self</Button>
        <Button onClick={hrefToSelf}>Href to self</Button>
        <Button onClick={hrefToOtherTab}>Href to other tab</Button>
      </div>
    </div>
  );
}
