import { TeamsFxContext } from "./Context";
import { useContext } from "react";
import {
  Label,
  Image,
  Button,
} from "@fluentui/react-components";
import { app, authentication} from "@microsoft/teams-js";
import { useNavigate } from "react-router-dom";

export default function AuthenticationEnd() {
    const { themeString } = useContext(TeamsFxContext);
    console.log('AuthenticationEnd')
    const oAuthAccessToken = new URL(window.location.href).searchParams.get('access_token')
    const oAuthAccessTokenStoredAt = new URL(window.location.href).searchParams.get('access_token_stored_at')
    const oAuthExpiresAt = new URL(window.location.href).searchParams.get('expires_at')
    const oAuthRefreshToken = new URL(window.location.href).searchParams.get('refresh_token')
  
    // Initialize the Microsoft Teams SDK  
    app.initialize().then(() => {
      if(!oAuthAccessToken || !oAuthAccessTokenStoredAt || !oAuthExpiresAt || !oAuthRefreshToken) {
        authentication.notifyFailure('Authentication failed. Please try again.');
        return;
      }
      const oAuthParams = JSON.stringify({
        accessToken: oAuthAccessToken,
        accessTokenStoredAt: oAuthAccessTokenStoredAt,
        expiresAt: oAuthExpiresAt,
        refreshToken: oAuthRefreshToken
      })
      authentication.notifySuccess(oAuthParams)
    });

    function NavigateToTabButton() {
      const navigate = useNavigate();
    
      function handleClick() {
        navigate("/tab?tabUrl=https%3A%2F%2Fdev02.bee360.com%2Fdashboard%2Fhome%3Fstructure_id%3D97745%26intext_id%3D231");
      }
    
      return (
        <Button type="button" onClick={handleClick}>
          Close
        </Button>
      );
    }

    return (
        <div
        className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
      >
        <div className="authentication page">
            <Image src="bee_configuration_page.png" alt="Bee360 Authentication" style={{width:'25%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
            <h1 style={{fontSize: '1.5em', textAlign: 'center'}}> Add Bee360 to a Team </h1>
            <br/>
            <div style={{ display: 'flex', flexDirection: 'column', width:'85%', margin: 'auto'}}>
              <Label htmlFor="tabTitleInput" size="small" style={{display:'block'}}>
                Bee360 now integrates with Teams
              </Label>
              <Label htmlFor="tabUrlInput" size="small">
                Bee360 is now conected with Teams. You can now close the Popup.
              </Label>
              <NavigateToTabButton/>
          </div>
        </div>
      </div>
    );
  }

