import { useContext } from "react";
import { TeamsFxContext } from "./Context";
import { Button } from "@fluentui/react-components";
import { useNavigate, useLocation } from "react-router-dom";

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  let oAuthParameters = {} 
  let frameUrl: URL = new URL('https://ms-teams-embedded/demo.bee360.rocks');

  const searchParams = new URLSearchParams(window.location.search)
  const updatedTabUrl = searchParams.get('tabUrl')

  if (updatedTabUrl && updatedTabUrl !== localStorage.getItem('instanceUrl')) {
    console.log(`Tab: Updated instanceUrl in local storage to ${updatedTabUrl}`)
    localStorage.setItem('instanceUrl', updatedTabUrl)
  }

  const instanceUrl = localStorage.getItem('instanceUrl')
  if(instanceUrl) {
    frameUrl = new URL(instanceUrl)
  }
  if (state?.accessToken && state?.accessTokenStoredAt && state?.expiresAt && state?.refreshToken) {
    frameUrl.pathname = 'ms-teams-embedded/authorization/login/resolve-authentication'
    oAuthParameters = {accessToken: state.accessToken, accessTokenStoredAt: state?.accessTokenStoredAt, expiresAt: state?.expiresAt, refreshToken: state?.refreshToken}
  }

  function initializeIFrameCommunication(instance_url: string) {
    onmessage = ({origin, data, ports}) => {
      if (origin === instance_url) { // only if they recognize you
        switch(data) {
          case "negotiation":
            console.log('got negotiation')
            ports[0].postMessage(window.origin);
            break;
          case "begin":
            beginCommunication(ports[0]);
            break;
        }
      }
    }

    function beginCommunication(safePort: MessagePort) {
      safePort.onmessage = ({data}) => {
        switch(data) {
          case "requestAuthentication":
            console.log('Mobeele requested authentication - navigate to /authentication/start')
            navigate('/authentication/start')
            break;
          case "requestOAuthParameters":
            safePort.postMessage(oAuthParameters)
            break;
        };
      }
    }
  }

  
  initializeIFrameCommunication(frameUrl.origin)

  return (
    <div
      className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
    >
      <div>
        <iframe 
          id='bee360-content-frame' 
          title="Bee360 Mobeele"
          sandbox="allow-top-navigation-to-custom-protocols allow-forms allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-scripts allow-same-origin allow-modals allow-downloads" 
          allowFullScreen={true} 
          style={{width:'100%', height:'100%', border:'0px'}} 
          src={frameUrl.toString() ?? ''} 
          onLoad={() => console.log('Tab: Load tab iframe with ' + frameUrl)}/>
        <Button onClick={reload}>Reload IFrame</Button>
      </div>
    </div>
  );
}

function reload() {
  const iframe = (document.getElementById('bee360-content-frame') as HTMLIFrameElement)
  iframe.src += '';
}