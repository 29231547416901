// https://fluentsite.z22.web.core.windows.net/quick-start
import React, { useContext } from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
} from "@fluentui/react-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import { TeamsFxContext } from "./Context";
import config from "./config";
import TabConfiguration from "./TabConfiguration";
import AuthenticationStart from "./AuthenticationStart";
import AuthenticationEnd from "./AuthenticationEnd";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });
  return (
      <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
        <FluentProvider
          theme={
            themeString === "dark"
              ? teamsDarkTheme
              : themeString === "contrast"
              ? teamsHighContrastTheme
              : {
                  ...teamsLightTheme,
                  colorNeutralBackground3: "#eeeeee",
                }
          }
        >
          <BrowserRouter>
          {loading ? (
            <Spinner style={{ margin: 100 }} />
          ) : (
            <Routes>
              <Route path="/" element={<Tab />} />
              <Route path="/tab" element={<Tab />} />
              <Route path="/config" element={<TabConfiguration />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/termsofuse" element={<TermsOfUse />} />
              <Route path="/authentication/*">
                <Route path="start" element={<AuthenticationStart />} />
                <Route path="end" element={<AuthenticationEnd />} />
              </Route>
            </Routes>
          )}
        </BrowserRouter>
        </FluentProvider>
      </TeamsFxContext.Provider>
  );
}